import React from "react";
import { db } from "gongo-client-react";

import SymbolAutocomplete from "./SymbolAutocomplete";

export default function HoldingAdd({ portfolio, holdings }) {
  const ref = React.useRef();
  const [symbol, setSymbol] = React.useState(null);

  const symbolOnChange = (event, newValue) => setSymbol(newValue);

  function submit(e) {
    e.preventDefault();
    const elements = ref.current.elements;

    const holding = {
      yahooSymbol: symbol.value,
      qty: parseFloat(elements.qty.value),
      //price: parseFloat(elements.price),
      bookCost: parseFloat(elements.bookCost.value),
      //currentValue: parseFloat(elements.currentValue)
    };

    //const newHoldings = [...holdings.holdings, holding];

    db.collection("holdings").update(
      { _id: holdings._id },
      //{ $set: { holdings: newHoldings } }
      { $push: { holdings: holding } }
    );

    //for (let name of ["price", "qty"]) elements[name].value = "";
    //setSymbol(null);
  }

  return (
    <div>
      <form ref={ref} onSubmit={submit}>
        <SymbolAutocomplete onChange={symbolOnChange} value={symbol} />
        <input type="text" name="qty" placeholder="qty" size="3" />
        <input type="text" name="bookCost" placeholder="bookCost" size="4" />
        <input type="submit" />
      </form>
    </div>
  );
}
