import React from "react";
import { Routes, Route, Navigate } from "react-router-dom";

import { db, useGongoUserId, useGongoIsPopulated } from "gongo-client-react";
import "gongo-client-devtools";
import "gongo-client-devtools/dist/index.css";

import Portfolios from "./Portfolios";

import "./App.css";

const clientNamesFromLocation: Record<string, string> = {
  localhost: "Development",
  "huntleigh.wastelands.net": "Huntleigh Holdings Ltd",
  "acharya.wastelands.net": "Acharya Investments Ltd",
  "porty2.cofam.fund": "Cofam Investments Ltd",
};

function clientNameFromLocation() {
  return typeof window === "object"
    ? clientNamesFromLocation[window.location.hostname]
    : "Client unknown.  Please contact support";
}

function LoginPage() {
  const clientName = clientNameFromLocation();

  return (
    <div className="loginPage">
      <div>
        <div>PORTY</div>
        <style jsx>{`
          .clientName {
          }
        `}</style>
        <p className="clientName">{clientName}</p>
        <br />
        {/* @ts-expect-error: wrong import? */}
        <button onClick={() => db?.auth?.loginWithService("google")}>
          Google Login
        </button>
      </div>
    </div>
  );
}

function Home() {
  return <Navigate to="portfolios" />;
}

function App() {
  const isPopulated = useGongoIsPopulated();
  const userId = useGongoUserId();

  if (!isPopulated) return <div>Loading...</div>;

  if (!userId) return <LoginPage />;

  return (
    <div>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="portfolios/*" element={<Portfolios />} />
      </Routes>
    </div>
  );
}

export default App;
