import React from "react";

import {
  Paper,
  Table,
  TableContainer,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
} from "@mui/material";

import FlashChange from "../FlashChange";
import { human } from "../lib/utils";

const costLabels = {
  month: "-1M",
};

export default function Totals({ orderedTotals, period, useBookCost }) {
  let costLabel = "Cost";
  if (!useBookCost) {
    if (period === "week") costLabel = "-1W";
    else if (period === "month") costLabel = "-1M";
    else if (period === "3month") costLabel = "-3M";
    else if (period === "year") costLabel = "-1Y";
    else if (period === "5year") costLabel = "-5Y";
    else costLabel = "???";
  }

  const tdStyle = new Array(orderedTotals.length);
  tdStyle[orderedTotals.length - 1] = { fontWeight: 500 };

  console.log({ orderedTotals });

  return (
    <div>
      {/* <h3>Totals</h3> */}

      <TableContainer component={Paper}>
        <Table fullWidth aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell></TableCell>
              <TableCell align="right">
                <FlashChange>{costLabel}</FlashChange>
              </TableCell>
              <TableCell align="right">Gain</TableCell>
              <TableCell align="right">Value</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {orderedTotals.map((row, i) => (
              <TableRow key={row.name}>
                <TableCell align="left" style={tdStyle[i]}>
                  <FlashChange>{row.name}</FlashChange>
                  <br />(
                  <FlashChange>
                    {(row.proportion * 100).toFixed(1)}%
                  </FlashChange>
                  )
                </TableCell>
                <TableCell
                  style={tdStyle[i]}
                  align="right"
                  sx={{ verticalAlign: "top" }}
                >
                  <FlashChange>{human(row.bookCost)}</FlashChange>
                </TableCell>
                <TableCell style={tdStyle[i]} align="right">
                  <FlashChange>{human(row.gain)}</FlashChange>
                  <br />(
                  <FlashChange>
                    {(row.gainPercent * 100).toFixed(1)}%
                  </FlashChange>
                  )
                </TableCell>
                <TableCell
                  style={tdStyle[i]}
                  align="right"
                  sx={{ verticalAlign: "top" }}
                >
                  <FlashChange>{human(row.currentValue)}</FlashChange>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <br />
    </div>
  );
}
