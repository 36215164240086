import React, { useState, useMemo } from "react";
import { useGongoLive, useGongoSub } from "gongo-client-react";

import MakeSelect from "./lib/MakeSelect";
import StockLine from "./StockLine";
import HoldingAdd from "./HoldingAdd";

const sortFuncs = {
  alpha: (a, b) => a.stock.desc.localeCompare(b.stock.desc),
  stockGrowth: (a, b) => {
    if (!b.change) return -1;
    if (!a.change) return 1;
    return b.change.change - a.change.change;
  },
  gain: (a, b) => {
    return b.gain - a.gain;
  },
  gainPct: (a, b) => {
    return b.gainPercent - a.gainPercent;
  },
  proportion: (a, b) => {
    return b.proportion - a.proportion;
  },
};

function SortSelect({ sort, setSort }) {
  const dict = {
    alpha: "Alphabetically",
    stockGrowth: "Stock Growth (period)",
    gain: "Gain (Absolute)",
    gainPct: "Gain Percentage",
    proportion: "Proportion",
  };

  return <MakeSelect name="sort" value={sort} set={setSort} dict={dict} />;
}

function periodChange2(series, stock) {
  const previous = series[0];
  if (stock && stock.price) {
    const price = stock.price.price;
    return {
      previous,
      recent: [new Date(stock.price.updatedAt), price],
      change: (((price - previous[1]) / previous[1]) * 100).toFixed(2),
      bagger: (price / previous[1]).toFixed(1) + "x",
    };
  }

  const recent = series[series.length - 1];
  return {
    previous,
    recent,
    change: (((recent[1] - previous[1]) / previous[1]) * 100).toFixed(2),
    bagger: (recent[1] / previous[1]).toFixed(1) + "x",
  };
}

function Holdings({
  portfolio,
  holdings,
  totals,
  since,
  period,
  holdingsPreviousBySedol,
  transactions,
}) {
  //console.log("Holdings");
  const [sort, setSort] = useState("alpha");
  const [blurHoldings, setBlurHoldings] = useState(false);

  // TODO, period
  const yahooSymbols = holdings.holdings
    .map((holding) => holding.stock && holding.stock.yahooSymbol)
    .filter(Boolean);

  /* const stockHistorySub = */ useGongoSub(portfolio && "stockHistory", {
    symbols: yahooSymbols,
    since, // note this break caching!  TODO
    //period,  TODO, period support on server / yahoo-finance
  });

  const histories = useGongoLive((db) =>
    db
      .collection("stockHistory")
      .find({
        symbol: { $in: yahooSymbols },
        date: { $gt: since },
      })
      .sort("date", "asc")
  );
  // console.log({ histories });

  useMemo(() => {
    //console.time("other");
    const holdingsBySymbol = {};
    holdings.holdings.forEach((holding) => {
      if (holding.stock && holding.stock.yahooSymbol) {
        holdingsBySymbol[holding.stock.yahooSymbol] = holding;
        holding.stock.history = [];
      }
    });

    histories.forEach((history) => {
      holdingsBySymbol[history.symbol].stock.history.push(history);
    });

    holdings.holdings.forEach((holding) => {
      if (!holding.stock) return;
      if (holding.stock.type !== "equity") return;
      if (!holding.stock.history) return;
      const series = (holding.series = holding.stock.history.map((quote) => [
        quote.date.getTime(),
        quote.close,
      ]));
      holding.change =
        series && series.length ? periodChange2(series, holding.stock) : null;
    });
    //console.timeEnd("other");
  }, [histories, holdings.holdings]);

  const sortedHoldings = useMemo(
    () =>
      holdings.holdings
        .slice()
        .filter((h) => h.stock && h.stock.type === "equity")
        .sort(sortFuncs[sort]),
    [sort, holdings /*, histories */]
  );

  const transactionsBySedolOrSymbol = useMemo(() => {
    const transactionsBySedolOrSymbol = {};
    transactions.forEach((t) => {
      if (t.sedol) {
        if (!transactionsBySedolOrSymbol[t.sedol])
          transactionsBySedolOrSymbol[t.sedol] = [];
        transactionsBySedolOrSymbol[t.sedol].push(t);
      } else if (t.yahooSymbol) {
        if (!transactionsBySedolOrSymbol[t.yahooSymbol])
          transactionsBySedolOrSymbol[t.yahooSymbol] = [];
        transactionsBySedolOrSymbol[t.yahooSymbol].push(t);
      }
    });
    return transactionsBySedolOrSymbol;
  }, [transactions]);

  //console.log({ sortedHoldings });
  //console.log("Holdings return");
  return (
    <div>
      <div>
        Sort: <SortSelect sort={sort} setSort={setSort} />
        &nbsp; | &nbsp;
        <label>
          <span>Blur Holdings</span>
          <input
            type="checkbox"
            checked={blurHoldings}
            onChange={() => setBlurHoldings(!blurHoldings)}
          />
        </label>
      </div>
      <div style={s.stocklineContainer}>
        {sortedHoldings.map((holding, i) =>
          holding.stock.price.price ? (
            <StockLine
              key={holding.sedol || holding.yahooSymbol}
              holding={holding}
              previousHolding={
                holdingsPreviousBySedol &&
                holdingsPreviousBySedol[holding.sedol]
              }
              i={i}
              totals={totals}
              blurHoldings={blurHoldings}
              transactions={
                transactionsBySedolOrSymbol[
                  holding.sedol || holding.yahooSymbol
                ] || []
              }
            />
          ) : null
        )}
      </div>
      {portfolio.source === "manual" && (
        <HoldingAdd portfolio={portfolio} holdings={holdings} />
      )}
    </div>
  );
}

const s = {
  stocklineContainer: {
    marginTop: "10px",
  },
};

export default Holdings;
