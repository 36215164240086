import AppBar from "./AppBar";
import Portfolio from "./Portfolio/Portfolio";
import React from "react";
import {
  db,
  useGongoLive,
  useGongoSub,
  useGongoUserId,
} from "gongo-client-react";
import { Routes, Route, Link } from "react-router-dom";

function AddPortfolio({ userId }: { userId: string | null }) {
  const ref = React.useRef<HTMLFormElement>(null);

  async function submit(event: React.SyntheticEvent) {
    event.preventDefault();
    if (!(ref.current && userId)) return;

    const elements = ref.current.elements as HTMLFormControlsCollection & {
      name: HTMLInputElement;
      currency: HTMLInputElement;
    };
    const doc = db.collection("portfolios").insert({
      name: elements["name"].value,
      userIds: [userId],
      source: "manual",
      currency: elements["currency"].value,
    });

    const portfolioId = doc._id;

    db.call("addPortfolioToUser", { userId, portfolioId });
    db.call("manualTotalsInitial", { _id: portfolioId });
  }

  return (
    <div>
      <h2>Add New</h2>
      <form ref={ref} onSubmit={submit}>
        New: &nbsp;
        <input type="text" name="name" /> &nbsp;
        <input type="text" name="currency" defaultValue="USD" size={2} /> &nbsp;
        <input type="submit" />
      </form>
    </div>
  );
}

function Portfolios() {
  const userId = useGongoUserId();
  /* const portfoliosSub = */ useGongoSub(userId && "portfolios", { userId });

  const portfolios = useGongoLive((db) => db.collection("portfolios").find());

  return (
    <>
      <AppBar title="Portfolios" />

      <div style={{ textAlign: "center" }}>
        <h1>Portfolios</h1>
        <div>
          {portfolios.map((portfolio) => (
            <div key={portfolio._id}>
              <Link to={portfolio._id}>{portfolio.name}</Link>
            </div>
          ))}
        </div>

        <h2>Portfolio Views</h2>
        <div>coming soon</div>

        <AddPortfolio userId={userId} />
      </div>
    </>
  );
}

function PortfoliosRoutes() {
  return (
    <Routes>
      <Route path="/" element={<Portfolios />} />
      <Route path="/:id" element={<Portfolio />} />
    </Routes>
  );
}

export default PortfoliosRoutes;
