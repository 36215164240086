import React from "react";
import { db, useGongoLive, useGongoSub } from "gongo-client-react";
// import ObjectID from "bson-objectid";
import { DateTime } from "luxon";

import makeStyles from "@mui/styles/makeStyles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";

import AddTransaction from "./TransactionsAdd";

import { human, symbolFromSedol } from "../lib/utils";

const useStyles = makeStyles({
  table: {
    width: "100%",
  },
  buy: {
    color: "green",
  },
  sell: {
    color: "red",
  },
});

function Transactions({ portfolio, since, transactions: transactionsQR }) {
  const classes = useStyles();
  const [consolidate, setConsolidate] = React.useState(false);

  let total = 0;
  let transactions;

  if (consolidate) {
    const byId = {};
    for (let row of transactionsQR) {
      const entry =
        byId[row.sedol || row.symbol] ||
        (byId[row.sedol || row.symbol] = {
          _id: row.sedol || row.symbol,
          symbol: symbolFromSedol(row.sedol),
          sedol: row.sedol,
          qty: 0,
          amount: 0,
        });
      entry.qty += row.qty;
      entry.amount += row.amount;
      total += row.amount;
    }
    transactions = Object.values(byId).sort((a, b) =>
      a.symbol && b.symbol
        ? a.symbol.localeCompare(b.symbol)
        : a.sedol.localeCompare(b.symbol || b.sedol)
    );
  } else {
    transactions = new Array(transactionsQR.length);
    for (let i = 0; i < transactions.length; i++) {
      const row = transactionsQR[i];
      transactions[i] = {
        _id: row._id,
        dateAction: row.dateAction,
        symbol: row.sedol ? symbolFromSedol(row.sedol) : row.yahooSymbol,
        qty: row.qty,
        price: row.price,
        amount: row.amount,
      };
      total += row.amount;
    }
    //total = transactionsQR.reduce((acc, cur) => acc + cur.amount, 0);
  }
  //console.log(transactions);

  return (
    <div>
      <div>
        <label>
          Consolidate
          <input
            type="checkbox"
            checked={consolidate}
            onChange={(e) => setConsolidate(!consolidate)}
          />
        </label>
      </div>

      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Date</TableCell>
              <TableCell>Symbol</TableCell>
              <TableCell>Qty</TableCell>
              <TableCell>Price</TableCell>
              <TableCell>Total</TableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            {transactions.map((row) => {
              const className =
                classes[row.type || (row.amount >= 0 ? "buy" : "sell")];
              return (
                <TableRow key={row._id}>
                  <TableCell className={className}>
                    {row.dateAction &&
                      DateTime.fromJSDate(row.dateAction).toFormat(
                        "yyyy-LL-dd"
                      )}
                  </TableCell>
                  <TableCell className={className}>
                    {row.symbol || `[${row._id}]`}
                  </TableCell>
                  <TableCell className={className}>{row.qty}</TableCell>
                  <TableCell className={className}>{row.price}</TableCell>
                  <TableCell className={className}>
                    {human(row.amount)}
                  </TableCell>
                </TableRow>
              );
            })}

            {portfolio.source === "manual" && (
              <AddTransaction portfolio={portfolio} />
            )}

            <TableRow>
              <TableCell>Total:</TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
              <TableCell className={classes[total >= 0 ? "buy" : "sell"]}>
                {human(total)}
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
}

export default Transactions;
