export default function computeTotalsFromHoldings(holdings, previousTotals) {
  const totals = {
    equity: { name: "Equity", bookCost: 0, currentValue: 0 },
    // other: { name: 'Other', bookCost: 0, currentValue: 0 },
    cash: { name: "Cash", bookCost: 0, currentValue: 0 },
  };

  // console.log({ holdings, previousTotals });

  holdings.forEach((row) => {
    if (!row.stock) return;

    if (row.stock.type === "cash") {
      totals.cash.bookCost += row.bookCost;
      if (row.currentValue === 0 && row.stock.desc.match(/^Capital Cash/))
        totals.cash.currentValue += row.bookCost;
      else totals.cash.currentValue += row.currentValue;
    } else {
      totals.equity.bookCost += row.bookCost;
      totals.equity.currentValue += row.currentValue;
    }
  });

  if (previousTotals) {
    totals.equity.bookCost = previousTotals.equity.currentValue;
    totals.cash.bookCost = previousTotals.cash.currentValue;
  }

  totals.total = {
    name: "Total",
    bookCost: previousTotals
      ? previousTotals.total.currentValue
      : totals.equity.bookCost +
        totals.cash.bookCost /*+ totals.other.bookCost */,
    currentValue:
      totals.equity.currentValue +
      totals.cash.currentValue /*+ totals.other.currentValue */,
  };

  const orderedTotals = [
    totals.equity,
    /* totals.other, */ totals.cash,
    totals.total,
  ];

  orderedTotals.forEach((row, i) => {
    row.gain = row.currentValue - row.bookCost;
    row.gainPercent = row.gain / row.bookCost;
    row.proportion = row.currentValue / totals.total.currentValue;
  });

  totals.ordered = orderedTotals;

  // console.log({ totals });
  return totals;
}
