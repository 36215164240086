import React, { useRef, useEffect } from "react";

export function usePrevious<T>(value: T) {
  const ref = useRef<T>();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
}

export function compare(_a: React.ReactNode, _b: React.ReactNode) {
  if (_a === _b) return 0;

  let a = _a,
    b = _b;
  if (Array.isArray(a) && Array.isArray(b)) {
    a = a.join("");
    b = b.join("");
  }
  if (typeof a === "string" && typeof b === "string") {
    const am = a.match(/([0-9.]+)/);
    const bm = b.match(/([0-9.]+)/);
    if (am && bm) {
      a = parseFloat(am[0]); //?
      b = parseFloat(bm[0]); //?
    }
  }
  if (typeof a === "number" && typeof b === "number") {
    return a - b;
  }
  return 0;
}

export default function FlashChange({
  children,
}: {
  children: React.ReactNode;
}) {
  const ref = useRef<HTMLSpanElement>(null);

  // React.Children.only(children);

  const current = children;
  const previous = usePrevious(current);
  let updated = previous && previous !== current;
  let className = "dom-update-highlight";

  const compared = compare(current, previous);
  if (compared > 0) className = "change-highlight-gt";
  else if (compared < 0) className = "change-highlight-lt";
  else updated = false;

  if (updated) {
    // console.log({ current, previous });
    // console.log(compared, className);
  }

  useEffect(() => {
    if (updated && ref.current) {
      ref.current.classList.add(className);
      const timeout = setTimeout(
        () => ref.current && ref.current.classList.remove(className),
        3500
      );
      return () => clearTimeout(timeout);
    }
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    return () => {};
  });

  return (
    <span ref={ref} data-updated={updated}>
      {current}
    </span>
  );
}
