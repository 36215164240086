import React from "react";

export default function MakeSelect({ value, set, dict, name }) {
  return (
    <select name={name} value={value} onChange={(e) => set(e.target.value)}>
      {Object.entries(dict).map(([val, label]) => (
        <option key={val} value={val}>
          {label}
        </option>
      ))}
    </select>
  );
}
