import React from "react";
import { Tooltip } from "react-tooltip";
import { format } from "date-fns";

import FlashChange from "../../FlashChange";
import { human } from "../../lib/utils";

function HoldingsLine({
  holdingUpdatedAt,
  holdingQty,
  holdingBookCost,
  holdingCurrentValue,
  holdingSedol,
  holdingFxRate,
  totalsTotalCurrentValue,
  totalsEquityCurrentValue,
  requestCurrencySymbol,
  previousHolding,
  blurHoldings,
  transactions,
  stockPrice,
  stockPriceCurrencySymbol,
}) {
  const proportion = (holdingCurrentValue / totalsTotalCurrentValue) * 100;
  const equityProp = (holdingCurrentValue / totalsEquityCurrentValue) * 100;

  const bookCost = previousHolding
    ? previousHolding.currentValue
    : holdingBookCost;
  const gain = holdingCurrentValue - bookCost;
  const gainAbs = Math.abs(gain);
  const gainPct = (gain / bookCost) * 100;
  const gainColor = gain > 0 ? "green" : "red";

  const sortedTransactions = transactions.sort(
    (a, b) => b.dateTransaction - a.dateTransaction
  );

  let investLength;
  if (holdingCurrentValue >= bookCost)
    investLength = bookCost / holdingCurrentValue;
  else investLength = holdingCurrentValue / bookCost;
  const marketLength = 1 - investLength;

  return (
    <div className={blurHoldings && "blur"}>
      <style jsx>{`
        .holdings {
          font-size: 10px;
          width: 100%;
        }
        .holdings > div {
          display: inline-block;
          text-align: center;
        }
        .num {
          width: 50px;
        }
        .blur .shouldBlur {
          color: transparent;
          text-shadow: 0 0 8px rgba(0, 0, 0, 0.5);
        }
        .numDouble {
          width: 100px;
        }
        .barHolder {
          height: 1em;
          line-height: 1em;
          width: calc(100% - 250px);
        }
        .bar {
          height: 8px;
          display: inline-block;
        }
        [data-color-scheme="green"] .barLeft {
          background: #25a325;
        }
        [data-color-scheme="green"] .barRight {
          background: #69e869;
        }
        [data-color-scheme="red"] .barLeft {
          background: #e55e5e;
        }
        [data-color-scheme="red"] .barRight {
          background: #a00a0a;
        }
      `}</style>
      <div className="holdings" data-color-scheme={gain > 0 ? "green" : "red"}>
        <div
          className="num shouldBlur"
          data-tooltip-id={"bookCost_" + holdingSedol}
        >
          {requestCurrencySymbol} {human(bookCost)}
        </div>
        <Tooltip id={"bookCost_" + holdingSedol}>
          Qty: {holdingQty.toLocaleString()}
        </Tooltip>

        <div className="barHolder">
          <div
            className="bar barLeft"
            style={{ width: investLength * 100 + "%" }}
          >
            &nbsp;
          </div>
          <div
            className="bar barRight"
            style={{ width: marketLength * 100 + "%" }}
          >
            &nbsp;
          </div>
        </div>

        <div
          className="num shouldBlur"
          data-tooltip-id={"currentValue_" + holdingSedol}
        >
          <FlashChange>
            {requestCurrencySymbol + " " + human(holdingCurrentValue)}
          </FlashChange>
        </div>
        {holdingFxRate && (
          <Tooltip id={"currentValue_" + holdingSedol}>
            {holdingFxRate.map((row, i) => (
              <div key={i}>{row}</div>
            ))}
          </Tooltip>
        )}

        <div className="numDouble" style={{ color: gainColor }}>
          <b>
            <span className="shouldBlur">
              <FlashChange>
                {requestCurrencySymbol + " " + human(gainAbs)}
              </FlashChange>{" "}
            </span>
            <FlashChange>{"(" + gainPct.toFixed(1) + "%)"}</FlashChange>
          </b>
        </div>

        <div className="num">
          <span data-tooltip-id={"proportion_" + holdingSedol}>
            <FlashChange>{"◔ " + equityProp.toFixed(1) + "%"}</FlashChange>
          </span>
          <Tooltip id={"proportion_" + holdingSedol}>
            💰 {proportion.toFixed(1)}%
          </Tooltip>
        </div>
      </div>
      {sortedTransactions.length > 0 && (
        <div className="transactions">
          {sortedTransactions.map((transaction, i) => (
            <div
              key={i}
              style={{
                display: "inline-block",
                borderRadius: "5px",
                border: "1px solid #aaa",
                margin: "3px",
                padding: "2px 5px 2px 5px",
                fontSize: "80%",
                background:
                  // BUY: green if it went up since, red otherwise
                  // SELL: green if it went down since, red otherwise.
                  transaction.price < stockPrice
                    ? transaction.type === "buy"
                      ? "rgba(127,255,127,0.1)"
                      : "rgba(255,127,127,0.1)"
                    : transaction.price > stockPrice
                    ? transaction.type === "buy"
                      ? "rgba(255,127,127,0.1)"
                      : "rgba(127,255,127,0.1)"
                    : "rgba(127,127,127,0.1)",
              }}
              // data-transaction-price={transaction.price}
              // data-stock-price={stockPrice}
              data-tooltip-id={"transaction_holding_" + i}
            >
              {format(transaction.dateTransaction, "dd/MM")}
              {" | "}
              {transaction.type === "buy" ? "B" : "S"}{" "}
              {human(transaction.price * Math.abs(transaction.qty)).replace(
                / /,
                ""
              )}{" "}
              @ {stockPriceCurrencySymbol}
              {Math.round(transaction.price)}
              {/*
              <Tooltip id={"transaction_holding_" + i}>
                <div>
                  {transaction.type}
                  <br />
                  Qty: {human(transaction.qty)}
                  <br />
                  Total: {human(transaction.price * transaction.qty)}
                </div>
              </Tooltip>
              */}
            </div>
          ))}
        </div>
      )}
    </div>
  );
}

export default React.memo(HoldingsLine);
