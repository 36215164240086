export default function period2secs(period) {
  let days;
  if (period === "day") days = 1;
  else if (period === "week") days = 7;
  else if (period === "month") days = 31;
  else if (period === "3month") days = 31 * 3;
  else if (period === "year") days = 365;
  else if (period === "5year") days = 365 * 5;
  else throw new Error("invalid period: " + period);

  return days * 24 * 60 * 60 * 1000;
}
