import fx from "money";
import { db } from "gongo-client-react";

let lastFetch, cache;
function getRates() {
  const now = Date.now();
  if (!cache || now - lastFetch < 1000) {
    cache = db.collection("store").findOne({ _id: "fxRates" });
    lastFetch = now;
  }
  return cache;
}

function bigCurrency(from) {
  if (from === "GBp") return "GBP";
  else if (from === "ZAc") return "ZAR";
  else throw new Error("Unknown currency: " + from);
}

function myfx(value) {
  if (!(this instanceof myfx)) return new myfx(value);

  this.value = value;

  const result = getRates();
  fx.rates = result ? result.rates : {};
  fx.base = result ? result.base : "USD";
}

myfx.prototype.real = fx;

myfx.prototype.from = function from(from) {
  if (!from) {
    this.from = from;
    return this;
  }

  if (from[2] === from[2].toLowerCase()) {
    this.value /= 100;
    from = bigCurrency(from);
  }

  this.from = from;
  return this;
};

myfx.prototype.to = function to(to) {
  if (!this.from) return this.value;
  const out = fx.convert(this.value, { from: this.from, to });
  //console.log("from", this.from, this.value, "to", to, out);
  return out;
};

myfx.isLoaded = function () {
  return !!getRates();
};

myfx.updatedAt = function () {
  const rates = getRates();
  return rates && rates.updatedAt;
};

export default myfx;
export { bigCurrency };
export { fx as realFx };
