import db from "gongo-client";
import { Collection } from "gongo-client";
import HTTPTransport from "gongo-client/lib/transports/http";
import GongoAuth from "gongo-client/lib/auth";

import type { Holdings, Portfolio, PortfolioTotal, Stock } from "./schemas";

declare module "gongo-client" {
  class Database {
    collection(name: "holdings"): Collection<Holdings>;
    collection(name: "portfolios"): Collection<Portfolio>;
    collection(name: "portfolioTotals"): Collection<PortfolioTotal>;
    collection(name: "stocks"): Collection<Stock>;
  }
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const out: Record<string, any> = { db };

db.extend("auth", GongoAuth);
db.extend("transport", HTTPTransport, {
  pollInterval: 60 * 1000,
  pollWhenIdle: false,
  idleTimeout: 60 * 1000,
});

const store = db.collection("store");
store.persist();
db.subscribe("fxRates");

// just for dev
const cols = ["exchanges", "stocks"];
cols.forEach((col) => {
  out[col] = db.collection(col);
  out[col].persist();
  db.subscribe(col);
});

const colsThatNeedUserId = [
  "portfolios",
  "portfolioTotals",
  "holdings",
  "stockHistory",
  "transactions",
];
colsThatNeedUserId.forEach((col) => {
  out[col] = db.collection(col);
  out[col].persist();
});

/*
let lastUserId;
db.gongoStore.find({ _id: 'auth' }).limit(1).findAndWatch(results => {
  const auth = results[0];
  if (!auth) return;
  const userId = auth.userId;
  if (userId && userId !== lastUserId) {
    // TODO... unsub existing?  n
    lastUserId = userId;
    colsThatNeedUserId.forEach(col => {
      db.subscribe(col, { userId });
    });
  }
});
*/

if (typeof window !== "undefined") {
  // @ts-expect-error: ok
  window.db = db;
}
