import React from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
//import ReactHighcharts from "react-highcharts";
import { DateTime } from "luxon";
import db from "gongo-client";

import { human } from "../lib/utils";

export default function Chart({ portfolioId, period, since }) {
  const portfolioTotals = db
    .collection("portfolioTotals")
    .find({ portfolioId, date: { $gt: since } })
    .sort("date", "asc")
    .toArraySync();

  console.log({ portfolioTotals });

  const cashAmounts = [];
  const cashProportions = [];
  const gainAmounts = [];
  const totalAmounts = [];

  let maxY = 0;
  portfolioTotals.forEach((totals) => {
    gainAmounts.push([totals.date.getTime(), totals.totals.equity.gain]);
    cashAmounts.push([totals.date.getTime(), totals.totals.cash.currentValue]);
    cashProportions.push([
      totals.date.getTime(),
      totals.totals.cash.proportion,
    ]);
    totalAmounts.push([
      totals.date.getTime(),
      totals.totals.total.currentValue,
    ]);

    /*
    for (let i = 0; i < dateRow.totals.length; i++) {
      const parsedDate = Date.parse(dateRow.date);
      //seriesData[i].data.push([ Date.parse(dateRow.date), dateRow.totals[i].currentValue ]);

      gainAmounts.push([parsedDate, dateRow.totals[0].gain]);

      cashAmounts.push([parsedDate, dateRow.totals[1].currentValue]);
      cashProportions.push([parsedDate, dateRow.totals[1].proportion]);

      totalAmounts.push([parsedDate, dateRow.totals[2].currentValue]);

      /*
      let maybeMax = dateRow.totals[1].currentValue;
      if (maybeMax > maxY)
        maxY = maybeMax;
      */
    //}
  });

  const seriesData = [
    {
      name: "Equity Gain",
      yAxis: 0,
      data: gainAmounts,
    },
    {
      name: "Cash Total",
      yAxis: 1,
      data: cashAmounts,
    },
    {
      name: "Total",
      yAxis: 2,
      data: totalAmounts,
    },
    {
      name: "Cash Percent",
      yAxis: 3,
      data: cashProportions,
    },
  ];

  const options = {
    chart: {
      type: "line",
    },
    credits: {
      enabled: false,
    },
    title: {
      text: "Totals (history)",
    },
    xAxis: {
      type: "datetime",
    },
    yAxis: [
      /*
      {
        //max: maxY,
        //endOnTick: false,
        title: {
          text: null,
        },
        labels: {
          style: {
            // color: Highcharts.getOptions().colors[0]
          },
        },
      },
      */

      {
        title: {
          text: null, // 'Equity Gain',
          style: {
            color: Highcharts.getOptions().colors[0],
          },
        },
        labels: {
          style: {
            color: Highcharts.getOptions().colors[0],
          },
        },
      },
      {
        title: {
          text: null, // 'Cash Total',
          style: {
            color: Highcharts.getOptions().colors[1],
          },
        },
        labels: {
          style: {
            color: Highcharts.getOptions().colors[1],
          },
        },
      },
      {
        title: {
          text: null, // 'Total',
          style: {
            color: Highcharts.getOptions().colors[2],
          },
        },
        labels: {
          enabled: false,
          style: {
            color: Highcharts.getOptions().colors[2],
          },
        },
      },
      {
        title: {
          text: null, // 'Cash Percent',
          style: {
            color: Highcharts.getOptions().colors[3],
          },
        },
        // min: 0,
        // max: 1,
        labels: {
          style: {
            color: Highcharts.getOptions().colors[3],
          },
          formatter: function () {
            return (this.value * 100).toFixed(2) + "%";
          },
        },
        opposite: true,
      },
    ],
    tooltip: {
      shared: true,
      formatter: function () {
        var s = DateTime.fromMillis(this.x).toLocaleString({
          weekday: "long",
          month: "short",
          day: "numeric",
          year: "numeric",
        });

        /*
        this.points.forEach(point => {
          s += '<br/><span style="color:' + point.color + '">\u25CF</span> '
            + point.series.name + ': <b>' + human(point.y) + '</b>';
        });
        */

        s +=
          '<br/><span style="color:' +
          this.points[2].color +
          '">\u25CF</span> ' +
          this.points[2].series.name +
          ": <b>" +
          human(this.points[2].y) +
          "</b>";

        s +=
          '<br/><span style="color:' +
          this.points[1].color +
          '">\u25CF</span> ' +
          this.points[1].series.name +
          ": <b>" +
          human(this.points[1].y) +
          "</b>";

        s +=
          '<br/><span style="color:' +
          this.points[3].color +
          '">\u25CF</span> ' +
          this.points[3].series.name +
          ": <b>" +
          (this.points[3].y * 100).toFixed(2) +
          "%</b>";

        s +=
          '<br/><span style="color:' +
          this.points[0].color +
          '">\u25CF</span> ' +
          this.points[0].series.name +
          ": <b>" +
          human(this.points[0].y) +
          "</b>";

        return s;
      },
    },
    plotOptions: {},
    series: seriesData,
  };

  console.log({ options });

  return <HighchartsReact highcharts={Highcharts} options={options} />;
}
