import React, { useState, useMemo } from "react";
import { useGongoOne, useGongoLive, useGongoSub } from "gongo-client-react";
import { useParams } from "react-router-dom";

import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import makeStyles from "@mui/styles/makeStyles";

import MakeSelect from "./lib/MakeSelect";
import computeTotalsFromHoldings from "../shared/computeTotalsFromHoldings";
import period2secs from "../shared/period2secs";

import AppBar from "../AppBar";
import Holdings from "./Holdings";
import Totals from "./Totals";
import Chart from "./Chart";
import Transactions from "./Transactions";

import HideOnScroll from "../lib/HideOnScroll";
import fx, { bigCurrency } from "../lib/fx";

const s = {
  title: {
    margin: "5px",
    fontSize: "150%",
    fontWeight: "bold",
    width: "calc(100% - 20px)",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  td: {
    padding: "5px 10px 5px 10px",
    border: "1px solid black",
  },
};

function updateHoldingValueFromStock(portfolioCurrency, origHolding, stock) {
  const holding = { ...origHolding };

  if (!stock) return holding;

  if (stock.type === "cash") {
    // TODO, think about this some more.
    holding.currentValue = holding.bookCost;
    return holding;
  }

  if (stock.type !== "equity") return holding;

  const price = stock.price;

  if (price.currency === portfolioCurrency) {
    holding.price = price.price;
  } else if (fx.isLoaded()) {
    holding.price = fx(price.price).from(price.currency).to(portfolioCurrency);

    let from = price.currency;
    if (from[2] === from[2].toLowerCase()) from = bigCurrency(from);

    holding.fxRate = [
      portfolioCurrency +
        " 1 = " +
        from +
        " " +
        Math.round(fx(1).from(portfolioCurrency).to(from) * 100) / 100,
      from +
        " 1 = " +
        portfolioCurrency +
        " " +
        Math.round(fx(1).from(from).to(portfolioCurrency) * 100) / 100,
      fx.updatedAt().toLocaleString(),
    ];
  }

  holding.currentValue = holding.qty * holding.price;
  holding.__updatedAt = stock.__updatedAt;

  /*
  if (
    holding.price === origHolding.price &&
    holding.currentValue === origHolding.currentValue
  )
    return origHolding;
  */

  return holding;
}

function computeTotalsWithStyles(holdings, previousTotals) {
  const totals = computeTotalsFromHoldings(holdings, previousTotals);

  totals.equity.tdStyle = totals.cash.tdStyle =
    /* totals.other.tdStyle = */ s.td;
  totals.total.tdStyle = Object.assign({ fontWeight: "bold" }, s.td);

  return totals;
}

function BookPeriodSelect({ bookPeriod, setBookPeriod }) {
  const dict = {
    book: "Book Cost",
    period: "Period Gain",
  };

  return (
    <MakeSelect
      name="bookPeriod"
      value={bookPeriod}
      set={setBookPeriod}
      dict={dict}
    />
  );
}

function PeriodSelect({ period, setPeriod }) {
  const dict = {
    day: "1 Day",
    week: "1 Week",
    month: "1 Month",
    "3month": "3 Months",
    year: "1 Year",
    "5year": "5 Years",
  };

  return (
    <MakeSelect name="period" value={period} set={setPeriod} dict={dict} />
  );
}

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && children}
    </div>
  );
}

/*
TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};
*/

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    // backgroundColor: theme.palette.background.paper,
    backgroundColor: "#eee",
    textAlign: "center",
    maxWidth: "600px",
    marginLeft: "auto",
    marginRight: "auto",
    padding: "10px",
  },
}));

function Portfolio() {
  const { id } = useParams();
  const hashParams = new URLSearchParams(window.location.hash.slice(1));
  const portfolioId = id;
  const [period, setPeriod] = useState("month");
  const [bookPeriod, setBookPeriod] = useState("book");
  const [currentTab, setTabIndex] = useState(0);
  const requestedDate = hashParams.get("date");

  const classes = useStyles();
  const setTab = (event, newValue) => setTabIndex(newValue);
  const useBookCost = bookPeriod === "book";

  const portfolio = useGongoOne((db) =>
    db.collection("portfolios").find({ _id: id })
  );

  console.log({ portfolio });

  /* const portfolioTotalsSub = */ useGongoSub(
    portfolio && "portfolioTotals",
    portfolio && { portfolioId: portfolio._id }
  );

  // TODO maybe cheaper to just check if we have today/yeserday/dayBefore
  const portfolioTotalsLatest = useGongoLive(
    portfolio &&
      ((db) =>
        portfolio &&
        db
          .collection("portfolioTotals")
          .find(
            requestedDate
              ? { portfolioId: portfolio._id, date: new Date(requestedDate) }
              : {
                  portfolioId: portfolio._id,
                }
          )
          .sort("date", "desc")
          .limit(1))
  )[0];

  // memo this so that useGongoLive gets same `since`.
  const since = useMemo(
    () =>
      portfolioTotalsLatest &&
      new Date(portfolioTotalsLatest.date.getTime() - period2secs(period)),
    [period, portfolioTotalsLatest]
  );
  // console.log(new Date(Date.now() - period2secs(period)));
  // console.log(period, since, since && since.getTime());

  const portfolioTotalsPrevious = useGongoLive(
    portfolio &&
      !useBookCost &&
      ((db) =>
        portfolio &&
        db
          .collection("portfolioTotals")
          .find({
            portfolioId: portfolio._id,
            date: { $gte: since }, // <--
          })
          .sort("date", "asc")
          .limit(1))
  )[0];

  //console.log({ portfolioTotalsLatest, portfolioTotalsPrevious });

  /* const portfolioTotalsLatestSub = */ useGongoSub(
    portfolioTotalsLatest && "holdings",
    portfolioTotalsLatest && {
      portfolioId: portfolio._id,
      date: portfolioTotalsLatest.date,
    }
  );

  /* const portfolioTotalsPreviousSub = */ useGongoSub(
    portfolioTotalsPrevious && "holdings",
    portfolioTotalsPrevious && {
      portfolioId: portfolio._id,
      date: portfolioTotalsPrevious.date,
    }
  );

  const holdings = useGongoOne(
    portfolioTotalsLatest &&
      ((db) =>
        db.collection("holdings").find({
          portfolioId: portfolio._id,
          date: portfolioTotalsLatest.date,
        }))
  );

  const holdingsPrevious = useGongoOne(
    portfolioTotalsPrevious &&
      ((db) =>
        db.collection("holdings").find({
          portfolioId: portfolio._id,
          date: portfolioTotalsPrevious.date,
        }))
  );

  const holdingSedols =
    holdings && holdings.holdings.map((holding) => holding.sedol);

  const holdingYahooSymbols = [];
  holdings &&
    holdings.holdings.forEach((holding) => {
      if (!holding.sedol && holding.yahooSymbol)
        holdingYahooSymbols.push(holding.yahooSymbol);
    });

  // TODO, for yahoosymbols
  const holdingsPreviousBySedol = {};
  if (holdingsPrevious) {
    for (let holding of holdingsPrevious.holdings) {
      holdingsPreviousBySedol[holding.sedol] = holding;
      if (!holdingSedols.includes(holding.sedol))
        holdingSedols.push(holding.sedol);
    }
  }

  //console.log({ holdings, holdingsPrevious });

  const stocks = useGongoLive(
    holdingSedols &&
      ((db) =>
        db.collection("stocks").find({
          $or: [
            { sedol: { $in: holdingSedols } },
            { yahooSymbol: { $in: holdingYahooSymbols } },
          ],
        }))
  );

  //console.log(stocks);

  // This data is not used here, but we want updates to trigger re-renders.
  useGongoOne((db) => db.collection("store").find({ _id: "fxRates " }));

  /* const sub = */ useGongoSub("transactions", { portfolioId });

  // Originally in Transactions.js but moved here to re-use.
  const transactions = useGongoLive((db) =>
    db
      .collection("transactions")
      .find({
        portfolioId,
        dateAction: { $gte: since || 0 },
      })
      .sort("dateAction", "desc")
  );

  //if (!holdings || !stocks) return <div>loading</div>;

  const stocksDict = useMemo(() => {
    const stocksDict = {};
    stocks.forEach((stock) => {
      if (stock.sedol) stocksDict[stock.sedol] = stock;
      if (stock.yahooSymbol) stocksDict[stock.yahooSymbol] = stock;
    });
    return stocksDict;
  }, [stocks]);

  // Create a new array with a copy of all data, that we can mutate.
  const augmentedHoldings = useMemo(
    () =>
      holdings
        ? holdings.holdings.map((origHolding) => {
            const stock =
              stocksDict[origHolding.sedol || origHolding.yahooSymbol];
            const holding = updateHoldingValueFromStock(
              portfolio.currency,
              origHolding,
              stock
            );
            holding.stock = stock;
            return holding;
          })
        : [],
    [holdings, stocksDict, portfolio?.currency]
  );

  /*
  if (holdings) {
    for (let i = 0; i < holdings.holdings.length; i++) {
      const holding = holdings.holdings[i];
      const stock = stocksDict[holding.sedol || holding.yahooSymbol];
      holdings.holdings[i] = updateHoldingValueFromStock("USD", holding, stock);
    }
  }
  */

  if (!portfolio) return <div>notfound</div>;

  /*
  if (holdings)
    holdings.holdings.forEach((holding) => {
      //holding.stock = stocksDict[holding.sedol || holding.yahooSymbol];
      //updateHoldingValueFromStock("USD", holding, holding.stock);
      const stock = stocksDict[holding.sedol || holding.yahooSymbol];
      const updatedHolding = { ...holding, stock };
      updateHoldingValueFromStock("USD", updatedHolding, stock);
      updatedHoldings.push(updatedHolding);
      // TODO, maybe keep track of there being no update, do a sha hash, etc.
    });
  */

  const totals =
    holdings &&
    computeTotalsWithStyles(
      augmentedHoldings, // holdings.holdings,
      !useBookCost && portfolioTotalsPrevious.totals
    );

  if (holdings)
    /*holdings.holdings*/ augmentedHoldings.forEach((holding) => {
      //holding.series = holding.stock.history.map(quote => [ quote.date, quote.close ]);
      //holding.change = series && series.length ? periodChange2(series, holding.stock) : null;
      holding.proportion =
        (holding.currentValue / totals.total.currentValue) * 100;
      holding.equityProportion =
        (holding.currentValue / totals.equity.currentValue) * 100;

      const previousHolding = holdingsPreviousBySedol[holding.sedol];
      const bookCost = previousHolding
        ? previousHolding.currentValue
        : holding.bookCost;
      const gain = (holding.gain = holding.currentValue - bookCost);
      holding.gainPercent = gain / bookCost;
    });

  return (
    <div className={classes.root}>
      <HideOnScroll>
        <div
          style={{
            zIndex: 100,
            position: "fixed",
            width: "100%",
            top: 0,
            left: 0,
          }}
        >
          <AppBar title="Portfolio" noHide={true} position="static" />

          {/* className={classes.root} same width as holdings */}
          <div>
            <div
              style={{
                borderTop: "2px solid #88b",
                paddingBottom: "8px",
                background: "#aaf",
              }}
            >
              <div style={s.title}>{portfolio.name}</div>

              <div>
                Period: <PeriodSelect period={period} setPeriod={setPeriod} />
                &nbsp; | &nbsp;
                <BookPeriodSelect
                  bookPeriod={bookPeriod}
                  setBookPeriod={setBookPeriod}
                />
              </div>
            </div>

            <div style={{ background: "#1976d2", color: "#fff" }}>
              <Tabs value={currentTab} onChange={setTab} textColor="inherit">
                <Tab label="Totals" />
                <Tab label="Holdings" />
                <Tab label="Transactions" />
              </Tabs>
            </div>
          </div>
        </div>
      </HideOnScroll>

      {/* Same height as header */}
      <div style={{ height: 184.5 }} />

      <div>
        <p>
          {!useBookCost && (
            <span>
              Start period:{" "}
              {portfolioTotalsPrevious &&
                portfolioTotalsPrevious.date.toString().replace(/\(.*?\)$/, "")}
              <br />
            </span>
          )}
          <span>
            Holdings as at:{" "}
            {portfolioTotalsLatest &&
              portfolioTotalsLatest.date.toString().replace(/\(.*?\)$/, "")}
          </span>
        </p>
      </div>

      <div>
        <TabPanel value={currentTab} index={totals ? 0 : false}>
          <Totals
            orderedTotals={totals && totals.ordered}
            period={period}
            useBookCost={useBookCost}
          />
          <Chart portfolioId={portfolio._id} period={period} since={since} />
        </TabPanel>

        <TabPanel value={currentTab} index={holdings ? 1 : false}>
          <Holdings
            portfolio={portfolio}
            holdings={{ ...holdings, holdings: augmentedHoldings }}
            holdingsPreviousBySedol={holdingsPreviousBySedol}
            totals={totals}
            since={since}
            period={period}
            transactions={transactions}
          />
        </TabPanel>

        <TabPanel value={currentTab} index={2}>
          <Transactions
            portfolio={portfolio}
            since={since}
            transactions={transactions}
          />
        </TabPanel>
      </div>
    </div>
  );
}

export default Portfolio;
