import React from "react";
import Highcharts from "highcharts";
//import HighchartsReact from "highcharts-react-official";
import highchartsBoost from "highcharts/modules/boost";
import highchartsAnnotations from "highcharts/modules/annotations";

highchartsBoost(Highcharts);
highchartsAnnotations(Highcharts);

const queue = [];
let queueTimeout;
function checkQueue() {
  if (queueTimeout || !queue.length) return;
  queueTimeout = setTimeout(function () {
    // console.log(queue.length, 'shift');
    if (queue.length) queue.shift().call();
    queueTimeout = null;
    checkQueue();
  }, 20);
}
function enqueue(f) {
  queue.push(f);
  checkQueue();
}
function dequeue(f) {
  const idx = queue.indexOf(f);
  if (idx !== -1) queue.splice(idx, 1);
}

function Mycharts({ config }) {
  // return <HighchartsReact highcharts={Highcharts} options={config} />

  //const [state, setState] = useState(null);
  //const draw = () => setState(<HighchartsReact highcharts={Highcharts} options={config} />);

  const ref = React.useRef();
  const draw = () => Highcharts.chart(ref.current, config);

  React.useEffect(() => {
    enqueue(draw);
    return () => dequeue(draw);
  }, [config.series[0].data.length]);

  return <div ref={ref} />;
}

function StockLineChart({ holding, width, height, transactions }) {
  const stock = holding.stock;
  const series = holding.series || [];

  let config;
  if (series)
    config = {
      boost: {
        enabled: true,
        seriesThreshold: 1,
        //useGPUTranslations: true,
        usePreallocated: true,
        useAlpha: false,
        _debug: {
          timeSetup: true,
          timeSeriesProcessing: true,
          timeKDTree: true,
          timeBufferCopy: true,
          timeRendering: true,
        },
      },
      chart: {
        width: width,
        height: height,
        backgroundColor: "rgba(0,0,0,0)",
        animation: false,
      },
      credits: {
        enabled: false,
      },
      annotations: [
        {
          draggable: "",
          labelOptions: {
            // backgroundColor: "rgba(255,255,255,0.5)",
            verticalAlign: "top",
            // y: 15,
          },
          labels: transactions.map((t) => ({
            point: {
              xAxis: 0,
              yAxis: 0,
              x: t.dateTransaction.getTime(),
              y: t.price,
            },
            text: t.type === "buy" ? "B" : t.type === "sell" ? "S" : "?",
            shape: "connector",
            padding: 1,
            style: {
              fontSize: "5pt",
            },
          })),
        },
      ],
      title: "Hi",
      legend: { enabled: false },
      xAxis: {
        type: "datetime",
        tickPositions: [],
        title: { enabled: false },
        labels: { enabled: false },
      },
      yAxis: {
        title: { enabled: false },
      },
      series: [
        {
          name: stock.yahooSymbol || stock.sedol,
          data: series,
        },
      ],
      plotOptions: {
        series: {
          animation: false,
        },
      },
    };

  // if (config.annotations[0].labels.length) console.log(config);
  return <Mycharts config={config} />;
}

export default StockLineChart;
