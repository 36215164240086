import React from "react";
import { db } from "gongo-client-react";
import { Link } from "react-router-dom";

import makeStyles from "@mui/styles/makeStyles";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import HomeIcon from "@mui/icons-material/Home";

import HideOnScroll from "./lib/HideOnScroll";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  homeButton: {
    // @ts-expect-error: another day
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
  },
}));

function PortyAppBar({
  title,
  classes,
  position,
}: {
  title: string;
  classes: Record<string, string>;
  position?: React.ComponentProps<typeof AppBar>["position"];
}) {
  return (
    <AppBar position={position}>
      <Toolbar>
        <IconButton
          edge="start"
          className={classes.homeButton}
          color="inherit"
          aria-label="home"
          to="/portfolios"
          component={Link}
          size="large"
        >
          <HomeIcon />
        </IconButton>
        <Typography variant="h6" className={classes.title}>
          {title}
        </Typography>
        {/* @ts-expect-error: wrong import? */}
        <Button onClick={() => db?.auth?.clear()} color="inherit">
          Logout
        </Button>
      </Toolbar>
    </AppBar>
  );
}

export default function PortyAppBarWrapper(
  props: Omit<React.ComponentProps<typeof PortyAppBar>, "classes"> & {
    noHide?: boolean;
  }
) {
  const classes = useStyles();

  if (props.noHide)
    return (
      <div className={classes.root}>
        <PortyAppBar {...props} classes={classes} />
      </div>
    );

  return (
    <React.Fragment>
      <HideOnScroll>
        <div className={classes.root}>
          <PortyAppBar {...props} classes={classes} />
        </div>
      </HideOnScroll>
      <PortyAppBar {...props} classes={classes} position="static" />
      {/* <div className="MuiToolbar-regular" /> */}
    </React.Fragment>
  );
}
