import React from "react";
import { db, useGongoLive, useGongoSub } from "gongo-client-react";

import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";

import SymbolAutocomplete from "./SymbolAutocomplete";

export default function AddTransaction({ portfolio }) {
  const ref = React.useRef();
  const [symbol, setSymbol] = React.useState(null);

  const symbolOnChange = (event, newValue) => setSymbol(newValue);

  function checkSymbol(e) {
    const symbol = e.target.value;
    const stock = db.collection("stocks").findOne({ yahooSymbol: symbol });
  }

  function submit(e) {
    e.preventDefault();
    const elements = ref.current.elements;

    const stock = db.collection("stocks").findOne({ yahooSymbol: symbol });
    console.log(stock);

    //if (!stock) return alert("No support yet for new symbols - coming soon");

    const entry = {
      portfolioId: portfolio._id,
      dateAction: new Date(elements["date"].value),
      dateTransaction: new Date(elements["date"].value),
      price: parseFloat(elements["price"].value),
      qty: parseInt(elements["qty"].value),
      type: parseInt(elements["qty"].value) > 0 ? "buy" : "sell",
      currency: portfolio.currency,
      __ObjectIDs: ["portfolioId"],
    };

    if (stock) {
      entry.sedol = stock.sedol;
      entry.instrumentCurrency = stock.price.currency;
    } else {
      entry.yahooSymbol = symbol;
    }

    if (entry.currency === entry.instrumentCurrency)
      entry.amount = entry.price * entry.qty;
    else entry.amount = 0;
    // else return alert("No support yet for other currencies");

    console.log(entry);
    const inserted = db.collection("transactions").insert(entry);
    console.log(inserted);

    for (let name of ["price", "qty"]) elements[name].value = "";
    setSymbol(null);

    // temporary measure until we have a hook to do this on insert
    db.call("checkTransactionId", { _id: inserted._id });
  }

  return (
    <TableRow>
      <TableCell>
        <form id="txAdd" ref={ref} onSubmit={submit}>
          <input name="date" defaultValue="2021-07-19" size="8" />
        </form>
      </TableCell>
      <TableCell>
        {/*
        <input
          form="txAdd"
          ref={txAddSymbol}
          onChange={checkSymbol}
          name="symbol"
          defaultValue="GOOGL"
          size="8"
        />
        */}
        <SymbolAutocomplete onChange={symbolOnChange} value={symbol} />
      </TableCell>
      <TableCell>
        <input form="txAdd" name="qty" defaultValue="1" size="3" />
      </TableCell>
      <TableCell>
        <input form="txAdd" name="price" defaultValue="200" size="5" />
      </TableCell>
      <TableCell>
        <input form="txAdd" type="submit" value="Add" />
      </TableCell>
    </TableRow>
  );
}
