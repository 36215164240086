import { db } from "gongo-client-react";

import humanFormat from "human-format";

const humanOptsDec = { decimals: 1 };
const humanOptsNoDec = { decimals: 0 };

export function human(value) {
  if (value >= 1000000 || value <= -1000000)
    return humanFormat(value, humanOptsDec);
  else return humanFormat(value, humanOptsNoDec);
}

let stocksCollection;
const stocksBySedol = {};
export function symbolFromSedol(sedol) {
  if (!stocksCollection) stocksCollection = db.collection("stocks");

  const stock =
    stocksBySedol[sedol] ||
    (stocksBySedol[sedol] = stocksCollection.findOne({ sedol }));
  return stock && (stock.symbol || stock.yahooSymbol);
}
