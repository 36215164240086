import React from "react";
import { db } from "gongo-client-react";

import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import CircularProgress from "@mui/material/CircularProgress";
import { createFilterOptions } from "@mui/material/Autocomplete";
import Popper from "@mui/material/Popper";
import makeStyles from "@mui/styles/makeStyles";

const filterOptions = createFilterOptions({
  matchFrom: "start",
  stringify: (option) => option.value + " " + option.name,
});

const cache = {};
async function search(input) {
  if (cache[input]) return cache[input];

  return (cache[input] = await db.call("yahooFinance", {
    method: "search",
    args: [
      input,
      {
        newsCount: 0,
        enableCb: false,
        enableNavLinks: false,
        enableEnhancedTrivialQuery: false,
      },
    ],
  }));
}

const useStyles = makeStyles(() => ({
  paper: {
    // border: '1px solid',
    // padding: theme.spacing(1),
    // backgroundColor: theme.palette.background.paper,
    width: "fit-content",
  },
}));

const MyPopper = function (props) {
  const styles = useStyles();
  return <Popper {...props} style={styles.popper} placement="bottom-start" />;
};

function insertAltNoDupes(array, option) {
  // TODO binary search
  // for now, check for dupe and re-sort after below
  for (let i = 0; i < array.length; i++) {
    if (array[i].value === option.value) return;
  }
  array.push(option);
}

export default function SymbolAutocomplete({ onChange, value }) {
  const [open, setOpen] = React.useState(false);
  const [options, setOptions] = React.useState([
    { value: "---", name: "Loading..." },
  ]);
  const [loading, setLoading] = React.useState(false);
  //const loading = open && options.length === 0;

  const nonNullValue = !!value;

  // If a symbol is selected that isn't already in stocks, tell server.
  function onChangeCheck(event, newValue) {
    const stock = db.collection("stocks").findOne({ yahooSymbol: newValue });
    if (!stock) {
      console.log("getStock", { yahooSymbol: newValue.value });
      // no need to await, will go into next poll, and come back via subscription
      db.call("getStock", { yahooSymbol: newValue.value });
    }
    onChange(event, newValue);
  }

  React.useEffect(() => {
    onInputChange(null, "", "input");
  }, [nonNullValue]);

  // async function onInputChange(event: object, input: string, reason: string) {
  async function onInputChange(event, input, reason) {
    if (reason !== "input") return;

    const stocks = db
      .collection("stocks")
      .find({ yahooSymbol: { $exists: true } })
      .sort("yahooSymbol", "asc")
      .toArraySync();

    const options = stocks.map((stock) => ({
      value: stock.yahooSymbol,
      name: stock.desc,
    }));

    const filtered = filterOptions(options, {
      inputValue: input,
      getOptionLabel: (option) => option.value + " " + option.name, // duped in <Autoc>
    });

    if (filtered.length < 4) {
      setLoading(true);
      const results = await search(input);
      const quotes = results.quotes;

      quotes.forEach((quote) => {
        insertAltNoDupes(options, {
          value: quote.symbol,
          name: quote.shortname,
        });
      });

      // remove when "TODO binary search" above done
      options.sort((a, b) => a.name.localeCompare(b.name));
    }

    setOptions(options);
    setLoading(false);
  }

  return (
    <Autocomplete
      id="symbolAutocomplete"
      style={{ width: 125 }}
      open={open}
      onOpen={() => {
        setOpen(true);
      }}
      onClose={() => {
        setOpen(false);
      }}
      isOptionEqualToValue={(option, value) => option.value === value.value}
      getOptionLabel={(option) => option.value + " " + option.name}
      onInputChange={onInputChange}
      onChange={onChangeCheck}
      value={value}
      filterOptions={filterOptions}
      options={options}
      loading={loading}
      PopperComponent={MyPopper}
      renderInput={(params) => (
        <TextField
          {...params}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <React.Fragment>
                {loading ? (
                  <CircularProgress color="inherit" size={20} />
                ) : null}
                {params.InputProps.endAdornment}
              </React.Fragment>
            ),
          }}
        />
      )}
    />
  );
}
