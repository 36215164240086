import React from "react";
// import { Link } from "react-router-dom";
import { Tooltip } from "react-tooltip";
import { formatDuration, intervalToDuration } from "date-fns";

//import { useGongoOne, useGongoLive } from "gongo-client-react";
import FlashChange from "../FlashChange";
import HoldingsLine from "./StockLine/HoldingsLine";
import StockLineChart from "./StockLine/StockLineChart";

// try move to Portfolio.js / graphql query
const requestCurrencySymbol = "$";

const s = {
  stockLineFirst: {
    borderTop: "1px solid #888",
  },
  stockLineEven: {
    borderBottom: "1px solid #888",
    padding: "5px",
    textAlign: "left",
    background: "white",
    fontSize: "14px",
  },
  stockLineOdd: {
    borderBottom: "1px solid #888",
    padding: "5px",
    textAlign: "left",
    fontSize: "14px",
    background: "#f5f5f5",
  },
  table: {
    tableLayout: "fixed",
    width: "100%",
  },
  tdInfo: {
    width: "calc(100% - 150px)",
  },
  tdChart: {
    // Note: non 'px' strings used again by highcharts as ints
    width: 150,
    height: 75,
  },
  stockDesc: {
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    width: "100%",
  },
  exchange: { color: "#555" },
  symbolSeperator: { color: "#aaa" },
};

function StockLine({
  holding,
  i,
  totals,
  blurHoldings,
  previousHolding,
  transactions,
}) {
  const stock = holding.stock;
  const yahoo = stock.yahoo;
  const series = holding.series || [];
  const change = holding.change || { change: 0, recent: [0, 0] };

  let changeColor;
  if (change) changeColor = change.change > 0 ? "green" : "red";

  const stockLineStyle = Object.assign(
    {},
    i % 2 === 0 ? s.stockLineEven : s.stockLineOdd,
    i === 0 ? s.stockLineFirst : null
  );

  const updatedDuration = formatDuration(
    intervalToDuration(
      {
        start: stock.price.updatedAt,
        end: new Date(),
      },
      [stock.price.updatedAt, Date.now()]
    ),
    { format: ["years", "months", "days"] }
  );

  const updatedDurationInMs = Date.now() - stock.price.updatedAt.getTime();
  const UPDATE_DURATION_THRESHOLD = 1000 * 60 * 60 * 24 * 3; // 3 days
  const showUpdateDurationWarning =
    updatedDurationInMs > UPDATE_DURATION_THRESHOLD;

  let currencySymbol = stock.price?.currencySymbol;
  if (!currencySymbol && stock.price.currency) {
    currencySymbol = stock.price.currency;
    if (currencySymbol === "USD") currencySymbol = "$";
    else if (currencySymbol === "GBP") currencySymbol = "£";
  }

  return (
    <div style={stockLineStyle}>
      <table style={s.table}>
        <tbody>
          <tr>
            <td style={s.tdInfo}>
              <div style={s.stockDesc} title={stock.desc}>
                <b>{stock.desc}</b>
              </div>
              <div>
                <span style={s.exchange}>
                  {yahoo && yahoo.quote.fullExchangeName}
                </span>
                <span style={s.symbolSeperator}>:</span>
                {/* <Link to={`/stocks/sedol/${stock.sedol}`}> */}
                <span style={s.ticker}>{stock.yahooSymbol || stock.sedol}</span>
                {/* </Link> */}
                &nbsp;
                {yahoo ? (
                  <a
                    href={`https://finance.yahoo.com/quote/${stock.yahooSymbol}/`}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img
                      src="/yahoo.ico"
                      alt="Yahoo"
                      height="12px"
                      style={{ opacity: 0.3 }}
                    />
                  </a>
                ) : null}
                &nbsp;
                {yahoo
                  ? [
                      <span
                        data-tooltip-id={"pe_" + stock.sedol}
                        key={"pe1_" + stock.sedol}
                      >
                        - PE:{" "}
                        {yahoo.quote.trailingPE &&
                          yahoo.quote.trailingPE.toFixed(2)}
                      </span>,
                      <Tooltip
                        id={"pe_" + stock.sedol}
                        key={"pe2_" + stock.sedol}
                      >
                        <table>
                          <tbody>
                            <tr>
                              <td>Trailing PE</td>
                              <td>{yahoo.quote.trailingPE}</td>
                            </tr>
                            <tr>
                              <td>Forward PE</td>
                              <td>{yahoo.quote.forwardPE}</td>
                            </tr>
                          </tbody>
                        </table>
                      </Tooltip>,
                    ]
                  : null}
              </div>
              <div>
                <span data-tooltip-id={"price_" + stock.sedol}>
                  <FlashChange>
                    {currencySymbol +
                      (stock.price && stock.price.price.toFixed(2))}
                  </FlashChange>
                </span>
                &nbsp;
                <Tooltip id={"price_" + stock.sedol}>
                  <table>
                    <tbody>
                      <tr>
                        <td>updatedAt</td>
                        <td>{stock.price.updatedAt.toString()}</td>
                      </tr>
                      <tr>
                        <td>sourcedVia</td>
                        <td>{stock.price.sourcedVia}</td>
                      </tr>
                      <tr>
                        <td>source</td>
                        <td>{stock.price.source}</td>
                      </tr>
                      <tr>
                        <td>sourceName</td>
                        <td>{stock.price.sourceName}</td>
                      </tr>
                    </tbody>
                  </table>
                </Tooltip>
                <span style={{ color: changeColor }}>
                  <FlashChange>{change && change.change + "%"}</FlashChange>
                </span>
                <FlashChange>{change && ` (${change.bagger})`}</FlashChange>
                {showUpdateDurationWarning && (
                  <>
                    {" "}
                    <span data-tooltip-id={"updateWarning_" + stock.sedol}>
                      ⚠️
                    </span>
                    <Tooltip id={"updateWarning_" + stock.sedol}>
                      Last updated <b>{updatedDuration}</b> ago!
                    </Tooltip>
                  </>
                )}
              </div>
            </td>
            <td style={s.tdChart}>
              {series && (
                <StockLineChart
                  holding={holding}
                  width={s.tdChart.width}
                  height={s.tdChart.height}
                  transactions={transactions}
                />
              )}
            </td>
          </tr>
        </tbody>
      </table>

      <HoldingsLine
        stockPrice={stock.price?.price}
        stockPriceCurrencySymbol={currencySymbol}
        holdingUpdatedAt={holding.__updatedAt}
        holdingBookCost={holding.bookCost}
        holdingQty={holding.qty}
        holdingCurrentValue={holding.currentValue}
        holdingSedol={holding.sedol}
        holdingFxRate={holding.fxRate}
        previousHolding={previousHolding}
        totalsTotalCurrentValue={totals.total.currentValue}
        totalsEquityCurrentValue={totals.equity.currentValue}
        requestCurrencySymbol={requestCurrencySymbol}
        blurHoldings={blurHoldings}
        transactions={transactions}
      />
    </div>
  );
}

export default StockLine;
